import changeActiveMenu from './changeActiveMenu';

const vh = window.innerHeight * 0.01003;
document.documentElement.style.setProperty('--vh', `${vh}px`);

const handleScrollSpy = () => {
  const menuItems = document.querySelectorAll('.segment');
  menuItems.forEach((menuItem) => {
    const current = menuItem.getBoundingClientRect().top;
    const scrollPos = document.body.scrollTop;
    if ((window.innerHeight + window.pageYOffset) >= document.body.scrollHeight - 2) {
      changeActiveMenu(menuItems[menuItems.length - 1].id);
    } else if (scrollPos >= current - 100) {
      changeActiveMenu(menuItem.id);
    }
  });
};

export default handleScrollSpy;
