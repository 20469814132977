import React from 'react';
import Menu from './Menu';
import { allPages } from '../../pages';
import toggleTheme from '../../handlers/toggleTheme';
import darkModeIcon from '../../../assets/moon.svg';

const MenuBar = () => (
  <nav className="container-fluid row">
    <ul className="col-12">
      {allPages.map((item) => !item.hideFromNavbar && <Menu key={item.key} id={item.title.replace(' ', '')} title={item.title} />)}
      <li>
        <button type="button" className="btn btn-outline-dark btn-nav" onClick={() => toggleTheme()}>
          <img src={darkModeIcon} alt="Dark Mode" width={16} height={16} />
        </button>
      </li>
    </ul>
  </nav>
);

export default MenuBar;
