import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Card from '../components/card';
import LoadMore from '../components/shared/LoadMore';
import { ContentContext } from '../components/shared/ContentContext';

const Projects = ({ title, legend }) => {
  const [loadMore, setLoadMore] = useState(true);
  const content = useContext(ContentContext);
  const projects = content.Projects;

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadMore(false);
    });
    return () => clearTimeout(timer);
  }, [projects]);

  return (
    <>
      <legend>{legend ?? title}</legend>
      <div className="card-container row">
        {projects.slice(0, loadMore ? projects.length : 5).map((item) => (
          <Card key={item.name} {...item} />
        ))}
        { projects.length > 5 && (
          <LoadMore
            showMore={loadMore}
            callback={() => {
              setLoadMore(!loadMore);
              if (loadMore === true) window.zenScroll.to(document.querySelector('.card-container'));
            }}
          />
        )}
      </div>
    </>
  );
};

export default Projects;

Projects.defaultProps = { legend: null };
Projects.propTypes = { title: PropTypes.string.isRequired, legend: PropTypes.string };
