import React from 'react';
import MenuBar from '../menu';
import { Footer } from './Footer';
import { allPages } from '../../pages';

const DisplaySite = () => (
  <>
    <MenuBar />
    {allPages.map((item) => (
      <div key={item.key} className="container segment" id={item.title.replace(' ', '')}>
        <div className="row content-div">
          <div className="col-12 col-md-8 col-xl-6">
            <item.element {...item} />
          </div>
        </div>
      </div>
    ))}
    <div className="container">
      <div className="row">
        <Footer />
      </div>
    </div>
  </>
);

export default DisplaySite;
