import React from 'react';

const Footer = () => (
  <div className="col-12 footer">
    <img className="logo" src="./logo.svg" alt="logo" />
    <p className="footer-text">Copyright © 2024 Dominik Hauerstein</p>
  </div>
);

export { Footer };
