import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Card from '../components/card';
import LoadMore from '../components/shared/LoadMore';
import { ContentContext } from '../components/shared/ContentContext';

const Skillset = ({ title, legend }) => {
  const [loadMore, setLoadMore] = useState(true);
  const content = useContext(ContentContext);
  const skills = content.Skills;

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadMore(false);
    });
    return () => clearTimeout(timer);
  }, [skills]);

  return (
    <>
      <legend>{legend ?? title}</legend>
      <div className="card-container row">
        {skills.slice(0, loadMore ? skills.length : 5).map((item) => (
          <Card key={item.name} imgSize={80} {...item} padded extraClasses={item.class} />
        ))}
        {skills.length > 5 && (
          <LoadMore showMore={loadMore} callback={() => setLoadMore(!loadMore)} />
        )}
      </div>
    </>
  );
};

export default Skillset;

Skillset.defaultProps = { legend: null };
Skillset.propTypes = { title: PropTypes.string.isRequired, legend: PropTypes.string };
