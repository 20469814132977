import React from 'react';
import ReactDOM from 'react-dom/client';
import ZenScroll from 'zenscroll';
import App from './js/App';
import handleScrollSpy from './js/handlers/handleScrollSpy';
import './scss/index.scss';

ReactDOM.createRoot(document.getElementById('content')).render(<App />);

window.onhashchange = () => window.history.replaceState({}, document.title, '.');
window.onscroll = () => handleScrollSpy();
window.zenScroll = ZenScroll;
ZenScroll.setup();
