import React from 'react';
import PropTypes from 'prop-types';
import ExternalLink from '../shared/ExternalLink';
import WrapIf from '../../handlers/WrapIf';

const Card = (props) => {
  const {
    url,
    imgsrc,
    name,
    description,
    imgSize,
    padded,
    extraClasses,
  } = props;

  const isDarkMode = document.querySelector('.dark-mode-background') != null;

  return (
    <div className="col-12 col-sm-6 col-xl-4 card-wrapper">
      <div className={`card themable-background ${isDarkMode ? 'dark-mode-background' : ''}`}>
        <WrapIf condition={url} With="a" {...{ rel: 'noopener noreferrer', href: url, target: '_blank' }}>
          <img
            src={imgsrc}
            className={`card-img-top ${padded ? 'card-extra' : ''} ${extraClasses || ''}`}
            alt="..."
            {...imgSize && { height: imgSize }}
            width="auto"
          />
        </WrapIf>
        <div className="card-body">
          <h5 className={`card-title themable-text ${isDarkMode ? 'dark-mode-text' : ''}`}>{name}</h5>
          <p className={`card-text themable-text ${isDarkMode ? 'dark-mode-text' : ''}`}>{description}</p>
        </div>
        {
          url && (
            <div className="card-footer">
              <ExternalLink linkText="See more details" isDarkMode={isDarkMode} {...props} />
            </div>
          )
        }
      </div>
    </div>
  );
};

Card.defaultProps = {
  url: null,
  imgSize: null,
  padded: false,
  extraClasses: '',
};

Card.propTypes = {
  url: PropTypes.string,
  imgsrc: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imgSize: PropTypes.number,
  padded: PropTypes.bool,
  extraClasses: PropTypes.string,
};

export default Card;
